import { GridFilterOperator } from '@mui/x-data-grid-premium';
import CustomSingleSelectFilter from './CustomSingleSelectFilter';

export const customSingleSelectOperator: GridFilterOperator = {
  label: 'isAnyOf',
  value: 'isAnyOf',
  getApplyFilterFn: (filterItem) => {
    if (!filterItem.value?.length || filterItem.value.length === 0) {
      return null;
    }
    return ({ value }) => value === filterItem.value;
  },
  InputComponent: CustomSingleSelectFilter,
};