import React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-premium';

interface CustomMultiSelectFilterProps extends GridFilterInputValueProps {
  item: any;
  apiRef: React.RefObject<any>;
}

const CustomMultiSelectFilter = (props: CustomMultiSelectFilterProps) => {
  const { item, applyValue, apiRef } = props;

  const options = apiRef.current.getColumn(item.field).valueOptions || [];

  const handleSelectChange = (event: React.SyntheticEvent, newValues: any[]) => {
    applyValue({ ...item, value: newValues.map((option) => option.value) });
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option: any) => option.label || ''}
      value={options.filter((option: any) => item.value?.includes(option.value))}
      onChange={handleSelectChange}
      renderTags={(selectedOptions, getTagProps) =>
        selectedOptions.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search or select"
          variant="standard"
          fullWidth
        />
      )}
    />
  );
};

export default CustomMultiSelectFilter;