
import { reduce, isNumber, map, first, clone } from "lodash";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import fetcher from "../services/fetchJson";
import { FORM_CONTENT_URL } from "../services/serverUrl";
import { ContentColumn, ContentInfo } from "./azureTableHooks";
import { GridRowId } from "@mui/x-data-grid";

export type TFormFilter = { [key: string]: string | number };

export const useFormContent = (formAccessor?: string, queryParams: { [key: string]: string | number } = {}) => {

  const url = FORM_CONTENT_URL + `/${formAccessor}?` + reduce(queryParams, (result, value, key) => {
    return [
      ...result,
      `${key}=${isNumber(value) ? value : value.replaceAll(" ", "%20")}`
    ];
  }, [] as string[]).join("&");


  const {
    data: formContent,
    mutate: updateFormContent,
    error: formContentError,
    isLoading,
  } = useSWR<ContentInfo>(
    formAccessor ? [url, { method: 'GET' }] : null, 
    ([url, params]) => fetcher(url, params),
  );


  const title = useMemo(() => {
    if (!!formContent) {
      const { folderName, name } = formContent || {};
      return `${folderName || ''}${folderName ? ' / ' : ''}${name}`;
    }
  }, [formContent]);


  const headerRow = useMemo(() => {
    return {
      rowId: "header",
      cells: map(formContent?.columns, c => {
        return { type: "header", text: c.name, }
      })
    }
  }, [formContent]);


  const filterContent = useMemo(() => {
    if(!!formContentError) return [];
    return formContent?.filterContent ?? [];
  }, [formContent, formContentError]);


  const columns = useMemo(() => {
    return formContent?.columns ?? [];
  }, [formContent]);


  const rows = useMemo(() => {
    return formContent?.rows ?? [];
  }, [formContent]);


  const canSubmitForm = useMemo(() => {
    return !!formContent?.canSubmitForm;
  }, [formContent]);


  const queryCompleted = useMemo(() => {
    return !!formContent?.queryCompleted;
  }, [formContent]);


  const getOriginalRow = useCallback((rowId?: GridRowId) => {
    const row = clone(formContent?.rows.find(r => r.Ky === rowId));
    if(!row) throw new Error("Missing matching orignal row");
    return row;
  }, [formContent]);


  return {
    canSubmitForm,
    columns,
    filterContent,
    formContentError: formContentError?.data.message,
    headerRow,
    queryCompleted,
    title,
    rows,
    isLoading,
    getOriginalRow,
    updateFormContent,
  }
}
