import { ContentColumn } from "../../hooks/azureTableHooks";
import { GridCallbackDetails, GridCellParams, GridColumnResizeParams, GridDensity, GridFilterModel, GridLogicOperator, GridRowId, GridSortModel, MuiEvent } from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, map, some } from "lodash";
import { Badge, Button, IconButton, Stack, Typography } from "@mui/material";
import { Add, BuildCircle, Restore, Save } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { lightTheme } from "../../assets/theme";
import getTableStyle from "./Styles.ts";
import TableToolbar from "./TableToolbar";
import ActionColumnContent from "./Columns/ActionColumnContent";
import { RawFilterForm } from "./RawFilterForm";
import DataGrid from "../DataGrid";
import { ActionResultBoxProps } from "./ActionBox";
import { CellEditForm } from "./CellEditForm";
import ContactInfo from "../ContactInfo";
import { DataTableProps, Anchor } from ".";
import Drawer from "./Drawer";
import { useDataTableData, LIMIT } from "./hooks/useDataTableData";
import getDataColumnContent from "./Columns/DataColumnContent";
import { TableSettings, useTableSettings } from "../../hooks/tableSettingsHook";

export function DataTable(props: DataTableProps) {

  const { tableAccessor, setDialogData } = props;

  const { t } = useTranslation("translations");

  const { getTableSettings, setColumnWidth } = useTableSettings(tableAccessor);

  const [pinnedRowIds, setPinnedRowIds] = useState<GridRowId[]>([]);

  const [tableSettings, setTableSettings] = useState<TableSettings>(getTableSettings());

  // Filter state 
  const [visibleDrawer, setVisibleDrawer] = useState<"right" | "bottom" | undefined>();
  const [bottomDrawerContentType, setBottomDrawerContentType] = useState<"filter" | "editcell">();

  const [editableCell, setEditableCell] = useState<GridCellParams>();
  const [actionResult, setActionResult] = useState<ActionResultBoxProps | undefined>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const [density, setDensity] = useState<GridDensity>("compact");

  const {
    page,
    orderBy,
    canEdit,
    canSave,
    isSavingInProgress,
    tableName,
    tableRows,
    tableContent,
    tableContentError,
    pendingFilter,
    contentParams,
    getOriginalCellValue,
    getIsDeletedRow,
    getIsNewRow,
    getIsChangedValue,
    handlePageChange,
    handleCleanParams,
    handleClearTable,
    handleRowUpdate,
    handleAddNewRow,
    undoDeleteRemoteDataRow,
    handleSetPendingFilter,
    handleUpdateContentParams,
    handleRemoveDataRow,
    handleSaveTableData,
    handleFocusCell,
  } = useDataTableData(tableAccessor);

  /** Select table rows using remote data aggregated with local data. */
 

  const toggleDrawer = useCallback((anchor: Anchor, open: boolean) => {
    setVisibleDrawer(open ? anchor : undefined);
  }, []);

  // useEffect(() => {
  //   if (contentParams.rawQuery && tableContent?.errorMsg) {
  //     toggleDrawer("bottom", true);
  //   }
  // }, [tableContent, contentParams.rawQuery, toggleDrawer]);

  // Table actions

  useEffect(() => {
    handleClearTable();
    handleCleanParams();
    handleUpdateContentParams({ clauses: {}, rawQuery: undefined } );
    setActionResult(undefined);
  }, [tableAccessor]);


  const pinnedColumns = useMemo(() => {
    return tableContent?.columns.filter(column => !!column.readonly).map(column => column.accessor) || [];
  }, [tableContent?.columns]);


  const pinnedRows = useMemo(() => {
    return tableRows.filter(row => pinnedRowIds.includes(row.Ky));
  }, [pinnedRowIds, tableRows]);
 

  const scrollToTop = useCallback(() => {
    const selector = document?.querySelector('.MuiDataGrid-virtualScroller');
    if (selector) {
      selector.scrollTop = 0;
    }
  }, []);

  const setDataChangingAlert = useCallback(() => {
    setDialogData({
      title: t("datatable.alert_dialog.title"),
      type: "alert",
      dialogContentText: `${t("datatable.alert_dialog.content")}. ${t("contact_us")}`,
      children: <ContactInfo />
    });
  }, [setDialogData, t]);

  const addNewRow = useCallback(
    (duplicateId?: GridRowId) => {

      if (!canEdit) {
        setDataChangingAlert();
        return;
      }
     
      handleAddNewRow(duplicateId);

      if (!duplicateId) {
        setActionResult({ type: "info", message: t("datatable.actions.new_row_added") });
      } else {
        setActionResult({ type: "info", message: t("datatable.actions.duplicated_row_created") });
      }

      scrollToTop();
    },
    [handleAddNewRow, setActionResult]
  );

  const onDeleteClick = useCallback((id: GridRowId) => {
    if (!canEdit) {
      setDataChangingAlert();
      return;
    } else if (getIsDeletedRow(id)) {
      undoDeleteRemoteDataRow(id);
    } else {
      handleRemoveDataRow(id);
    }
    setActionResult({
      type: "warning",
      message: t("datatable.actions.row_removed", { rowId: getIsNewRow(id) ? " " : ` ${id} ` })
    });
  }, [canEdit, undoDeleteRemoteDataRow, setDataChangingAlert, t]);

  const onPinClick = useCallback((id: GridRowId) => {
    setPinnedRowIds((prevValue) => {
      if (prevValue.includes(id)) {
        return prevValue.filter((rowId) => rowId !== id);
      }
      return [...prevValue, id];
    })
  }, []);


  const columnsContent = useMemo(() => {
    if (tableContent) {
      console.log("COLUMNS", tableContent.columns)
      const columnsContent = map(tableContent.columns, (contentColumn: ContentColumn) => getDataColumnContent({
        ...contentColumn,
        width: tableSettings?.columns?.[contentColumn.accessor]?.width ?? contentColumn.width
      }));


      return [...columnsContent, ActionColumnContent(
        {
          pinnedRows: pinnedRowIds,
          canDelete: tableContent.deletableRows ?? false,
          deleteClick: onDeleteClick,
          duplicateClick: addNewRow,
          onPinClick: onPinClick,
        })];
    }
    else {
      return [];
    }
  }, [tableContent, addNewRow, onDeleteClick, pinnedRowIds]);

  const restoreTable = useCallback(() => {
    setDialogData({
      title: t("datatable.restore_dialog.title"),
      type: "confirm",
      dialogContentText: t("Changes in table {{tableName}} will be lost.", {
        tableName: tableContent?.name
      }),
      handleConfirm: () => {
        handleClearTable();
        setActionResult({ type: "success", message: t("datatable.actions.table_restored") });
      },
    });
  }, [setDialogData, tableContent?.name, setActionResult]);

  const handleLongCellChange = useCallback((cellParams: GridCellParams) => {
    const originalRow = cellParams.row;
    const updatedRow = { ...originalRow, [cellParams.field]: cellParams.value };
    handleRowUpdate(updatedRow, originalRow);
    setEditableCell(cellParams);
  }, [])


  const handleSortChange = useCallback((sortModel: GridSortModel) => {
    handleUpdateContentParams({ orderBy: sortModel });
  }, []);

  const handleOnSaveClick = () => {
    handleSaveTableData((success?: boolean) => {
      setActionResult({ type: success ? "success" : "error", message: success ? t("datatable.actions.saved") : t("datatable.actions.error") })
    })
  }

  const toolbarButtons = useMemo(() => {
    const commonButtons = [
      <Button
        key="toolbar_save_btn"
        disabled={!canSave}
        startIcon={<Save />}
        onClick={handleOnSaveClick}>
        {t("datatable.actions.save")}
      </Button>,
      <Button
        key="toolbar_restore_btn"
        disabled={!canSave}
        startIcon={<Restore />}
        onClick={restoreTable}>
          {t("datatable.actions.restore")}
      </Button>,
      <IconButton
        key="toolbar_raw_filter_btn"
        onClick={() => {
          setBottomDrawerContentType("filter");
          toggleDrawer("bottom", true);
        }}
      >
        <Badge variant="dot" color="primary" invisible={isEmpty(contentParams.rawQuery)}>
          <BuildCircle />
        </Badge>
      </IconButton>,
    ];

    return [
      <Button
        key="toolbar_add_btn"
        disabled={!!tableContentError}
        onClick={() => addNewRow()}
        startIcon={<Add />}
      >
        {t("datatable.actions.add")}
      </Button>,
      ...commonButtons
    ];
  }, [canSave, tableContentError, addNewRow, restoreTable, handleSaveTableData]);

  const GridToolbar = useCallback(() => {
    return <TableToolbar
      actionResult={actionResult}
      buttons={toolbarButtons} />;
  }, [toolbarButtons, actionResult]);

  const bottomDrawerContent = useMemo(() => {
    if (tableContent && bottomDrawerContentType === "filter") {
      return {
        title: t("filter"),
        element: <RawFilterForm
          tableName={tableAccessor}
          filter={pendingFilter.rawFilter ?? ""}
          columns={tableContent.columns}
          hideForm={!visibleDrawer}
          onFormHide={(filter: string) => handleSetPendingFilter({ ...pendingFilter, ...{ rawFilter: filter } })}
          rawQueryBaseScript={tableContent.rawQueryBaseScript}
          error={tableContent.errorMsg}
          onSubmit={(filter: string) => {
            handleSetPendingFilter({ ...pendingFilter, ...{ rawFilter: filter } });
            handleUpdateContentParams({ clauses: {}, rawQuery: filter } );
            toggleDrawer("bottom", false);
            setActionResult({ type: "info", message: t("datatable.actions.filtered") });
          }} />
      };
    } else if (editableCell && bottomDrawerContentType === "editcell") {
      return {
        title: t("datatable.editform", { row: editableCell.id, field: editableCell.field }),
        element: (
          <CellEditForm
            cellParams={editableCell}
            onChange={handleLongCellChange}
          />
        )
      };
    }
  }, [bottomDrawerContentType, editableCell, visibleDrawer, pendingFilter, t, tableAccessor, tableContent, toggleDrawer]);

  const handleCellDoubleClick = useCallback((params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if (!canEdit && params.colDef.field !== "Ky" && params.colDef.type !== "actions") {
      setDataChangingAlert();
    } else if (params.colDef.editable && event.altKey) {
      setBottomDrawerContentType("editcell");
      setEditableCell(params);
      toggleDrawer("bottom", true);
    } else if (!params.colDef.editable && params.colDef.type !== "actions") {
      setDataChangingAlert();
    }
  }, [canEdit, setDataChangingAlert, toggleDrawer])

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (params.colDef.type === "actions") return;

    const originalValue = getOriginalCellValue(params);
    setActionResult({
      type: "info",
      message: `${params.field}: ${params.formattedValue ?? params.value}${originalValue}`
    });
  }, [getOriginalCellValue, setActionResult])

  const handleSortingModeChange = useCallback((sortModel: GridSortModel) => {
    if (isEmpty(sortModel)) sortModel = orderBy!;
    const contentSortModel = !!orderBy?.length ? orderBy[0] : undefined;
    if (sortModel[0].field !== contentSortModel?.field || sortModel[0].sort !== contentSortModel?.sort) {
      handleSortChange(sortModel);
    }
  }, [orderBy, handleSortChange])

  const handleFilterChange = useCallback( (model: GridFilterModel, details: GridCallbackDetails<"filter">) => {
    setFilterModel(model);

    const hadPreviousFilterValues = some(filterModel.items, (item) => item.value !== undefined);
    const hasCurrentFilterValues = some(model.items, (item) => item.value !== undefined);

    if (hadPreviousFilterValues && !hasCurrentFilterValues || hasCurrentFilterValues) {
      const cleanFilters = model?.items?.filter((item) => item.value !== undefined && item.value.length > 0);
      handleUpdateContentParams({ filter: cleanFilters  });
    }

  }, [filterModel]);

  const getCellClassName = useCallback((params: GridCellParams) => {
    if (getIsNewRow(params.id)) return "new-cell";
    return getIsChangedValue(params.id, params.field) ? "changed-cell" : "";
  }, [getIsChangedValue, getIsNewRow])

  const handleUpdateColumnWidth = useCallback((params: GridColumnResizeParams) => {
    const updatedSettings = setColumnWidth(params.colDef.field, params.width);
    setTableSettings(updatedSettings);
  }, [setColumnWidth]);

  const handleDensityChange = (density: GridDensity) => {
    setDensity(density)
  }

  return (
    <Stack sx={{ height: 'calc(100vh - 42px)' }} bgcolor="white">
      <Stack direction="row" justifyContent="center" alignItems="center" p={1} bgcolor="InfoBackground">
        <Typography variant="subtitle1">{tableName}</Typography>
      </Stack>
      <DataGrid
        key={tableAccessor}
        sx={getTableStyle(lightTheme)}
        columns={columnsContent}
        rows={tableRows}
        rowCount={tableContent?.rowsCount ?? 0}
        slots={{
          toolbar: GridToolbar,
          headerFilterMenu: null,
        }}
        slotProps={{
          filterPanel: {
            disableAddFilterButton: true,
            logicOperators: [GridLogicOperator.And]
          }
        }}
        filterModel={filterModel}
        pinnedRows={{
          top: pinnedRows
        }}
        disableColumnPinning
        pinnedColumns={{ left: pinnedColumns, right: ["actions"] }}
        disableRowSelectionOnClick
        disableRowGrouping
        disableAggregation
        density={density}
        rowHeight={32}
        onDensityChange={handleDensityChange}
        ignoreValueFormatterDuringExport
        cellSelection
        getCellClassName={getCellClassName}
        getRowId={r => r.Ky}
        getRowClassName={(params) => getIsDeletedRow(params.row.Ky) ? "deleted-row" : ""}
        loading={isSavingInProgress || (!tableContent && !tableContentError)}
        isCellEditable={(params: GridCellParams) => canEdit && !getIsDeletedRow(params.id)}
        onSortModelChange={handleSortingModeChange}
        processRowUpdate={(updatedRow, originalRow) => handleRowUpdate(updatedRow, originalRow)}
        pagination
        paginationMode="server"
        paginationModel={{ page, pageSize: LIMIT }}
        paginationMeta={{ hasNextPage: (tableContent?.rowsCount || 0) > page * LIMIT + LIMIT }}
        pageSizeOptions={[100]}
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        onPaginationModelChange={(paginationModel) => {
          handlePageChange(paginationModel.page);
        }}
        onColumnWidthChange={handleUpdateColumnWidth}
        onCellEditStart={(params: GridCellParams) => handleFocusCell(!params.colDef.editable || !canEdit)}
        onCellEditStop={() => handleFocusCell(false)}
        onCellClick={handleCellClick}
        onCellDoubleClick={handleCellDoubleClick}
        sortingMode="server"
      />
      <Drawer
        visibleDrawer={visibleDrawer}
        toggleDrawer={toggleDrawer}
        bottomDrawerTitle={bottomDrawerContent?.title}
        bottomDrawerComponent={bottomDrawerContent?.element}
        onFilterChange={handleSetPendingFilter}
        onContentParamsChange={handleUpdateContentParams}
        onActionResultChange={setActionResult}
        columns={tableContent?.columns || []}
        currentFilter={pendingFilter.exactFilter}
      />
    </Stack>
  );
}
